<template>
  <div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col>
          <title-and-description :field="field" />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col >
          <div class="form-group">
            <el-form-item label="Payable through">
              <el-select v-model="field.validations.paymentSystems" multiple placeholder="Select Methods"
                @change="updateRedirectUrl">
                <el-option v-for="item in getIntegratedList.cred_details" :key="item._id" :label="item.accountName"
                  :value="item._id">
                  <span style="float: left">{{ item.accountName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.pgType }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col >
          <div class="form-group">
            <el-form-item label="Fields">
              <el-select v-model="field.validations.entityVariable" :disabled="checkDisabled('field')"
                placeholder="Select Fields" @change="getCurrencyType" >
                <el-option v-for="item of getFields" :key="item.key + '_' + item.template_name" :value-key="'u_key'"
                  :label="getLabelName(item)" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col >
          <div class="form-group">
            <el-form-item class="currency" label=" Currency Types">

              <el-select v-model="field.validations.currency" filterable default-first-option>
                <el-option v-for="item in CurrencyTypes" :key="item.value" :label="item.value" :value="item.value">
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right;color: var(--el-text-color-secondary);font-size: 13px;
                  ">{{ item.name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-input placeholder="Default Redirection URL" v-model="field.validations.defaultRedirectUrl"
                style="display:none"></el-input>
      <!-- <el-row type="flex" :gutter="30">
        <el-col :md="12" :sm="12" :lg="8">
          <div class="form-group">
            <el-form-item label="Redirection URL">
              <el-input placeholder="Default Redirection URL" v-model="field.validations.defaultRedirectUrl"
                style="display:none"></el-input>
              <el-input placeholder="Redirection URL" v-model="field.validations.redirectUrl"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row> -->
      <h3>On Success Rules</h3>
      <hr />
      <table>
        <tr>
          <th>Entities</th>
          <th>Field</th>
          <th>Action</th>
          <th>Value</th>
          <th></th>
        </tr>
        <tr v-for="(sRule, sIndex) in field.validations.updateRules.success" :key="sIndex">
          <td>{{ sRule.entity_info.name }}</td>
          <td>{{ sRule.field.template_name }}/{{ sRule.field.label }}</td>
          <td>{{ sRule.action }}</td>
          <td>{{ sRule.value }}</td>
          <td>
            <el-link class="mr-1" type="danger" :underline="false" @click="deleteCondition('SUCCESS', fIndex)"><i
                class="el-icon-remove"></i>
            </el-link>
          </td>

        </tr>
        <tr>
          <td>
            <div class="form-group">
              <el-form-item>
                <el-select v-model="successRule.entity_info" no-data-text="No Entities available" filterable
                  default-first-option @change="setEntityFields('SUCCESS')">
                  <el-option 
                  v-for="(entity, index) of getAllEntities.data" 
                  :value="entity" 
                  :key="index"
                    :label="entity.name">
                    <span style="float: left">{{ entity.name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </td>
          <td>
            <div class="form-group">
              <el-form-item>
                <el-select v-model="successRule.field" :disabled="checkDisabled('field')" placeholder="Select Fields">
                  <el-option v-for="item of selectedEntityFields" :key="item.key + '_' + item.template_name"
                    :label="`${item.template_name} - ${item.label} - ${item.data_type} `" :value="item">{{
                      item.template_name }} - {{ item.label }}</el-option>
                </el-select>
              </el-form-item>
            </div>
          </td>
          <td>
            <div class="form-group">
              <el-form-item >
                <el-select v-model="successRule.action" placeholder="Action Type">
                  <el-option v-for="(item, i) of actionTypesList" :key="`${item}_Success_${i}`" :label="item"
                    :value="item"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </td>
          <td>
            <el-form-item>
              <el-input v-model="successRule.value" placeholder="value">
              </el-input>
            </el-form-item>
          </td>
          <td>
            <el-link class="mr-1" type="primary" :underline="false" @click="addNewCondition('SUCCESS', true)"><i
                class="el-icon-circle-plus"></i>
            </el-link>
          </td>

        </tr>

      </table>
      <h3>On Failure Rules</h3>
      <hr />

      <table>
        <tr>
          <th>Entities</th>
          <th>Field</th>
          <th>Action</th>
          <th>Value</th>
          <th></th>
        </tr>
        <tr v-for="(fRule, fIndex) in field.validations.updateRules.failure" :key="fIndex">
          <td>{{ fRule.entity_info.name }}</td>
          <td>{{ fRule.field.template_name }}/{{ fRule.field.label }}</td>
          <td>{{ fRule.action }}</td>
          <td>{{ fRule.value }}</td>
          <td>
            <el-link class="mr-1" type="danger" :underline="false" @click="deleteCondition('FAILURE', fIndex)"><i
                class="el-icon-remove"></i>
            </el-link>
          </td>
        </tr>
        <tr>
          <td>
            <el-select v-model="failureRule.entity_info" no-data-text="No Entities available" filterable
                default-first-option @change="setEntityFields('FAILURE')">
                <el-option v-for="(entity, index) of getAllEntities.data || []" :value="entity" :key="index"
                  :label="entity.name">
                  <span style="float: left">{{ entity.name }}</span>
                </el-option>
              </el-select>
          </td>
          <td>
            <el-select v-model="failureRule.field" :disabled="checkDisabled('field')" placeholder="Select Fields">
              <el-option v-for="item of selectedEntityFields" :key="item.key + '_' + item.template_name"
                :label="`${item.template_name} - ${item.label} - ${item.data_type} `" :value="item">{{
                  item.template_name }} - {{ item.label }}</el-option>
            </el-select>
          </td>
          <td>
            <el-select v-model="failureRule.action" placeholder="Action Type">
              <el-option v-for="(item, i) of actionTypesList" :key="`${item}_Success_${i}`" :label="item"
                :value="item"></el-option>
            </el-select>
          </td>
          <td><el-input v-model="failureRule.value" placeholder="value">
          </el-input></td>
          <td>
            <el-link class="mr-1" type="primary" :underline="false" @click="addNewCondition('FAILURE', true)"><i
              class="el-icon-circle-plus"></i>
          </el-link>
          </td>
        </tr>
        </table>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Config from "../../../config/app";
export default {
  name: "templates-formComponents-Payment",
  components: {
    "TitleAndDescription": () => import("./TitleAndDescription"),
    // "IsFieldRequired": () => import("./IsFieldRequired"),
    // "FieldFilledBy": () => import("./FieldFilledBy"),
    // "Placeholder": () => import("./Placeholder"),
  },

  props: ["field", "templatesData", "selfTemplate", "selfTemplateId", "isFromDocument"],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo", "getIntegratedList"]),

    ...mapGetters("entities", ["getAllEntities", "getEntityById", "getEntityDataByEntityId"]),

    allGlobalVariables() {
      console.log("getAllGlobalVariables", this.getAllGlobalVariables);
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
    getDocumentFields() {
      let elements = [];
      this.templatesData.forEach((el) => {
        if (
          this.field.key != el.key &&
          (el.type == "NUMBER" ||
            el.type == "DATE" ||
            el.type == "CURRENCY" ||
            el.type == "DATE" ||
            el.type == "LIST" ||
            el.type == "TIME" ||
            el.type == "DATE_TIME_RANGE" ||
            el.type == "FORMULA" ||
            el.type == "AGGREGATE_FUNCTION")
        ) {
          let obj = {
            template_id: null,
            template_name: "self",
            field_id: el._id,
            field_name: el.label,
            label: el.label,
            key: el.key,
            u_key: el.key,
            input_type: el.type,
          };
          if (el.type == "CURRENCY") {
            obj = {
              ...obj,
              ...{
                currency_type:
                  el.validations && el.validations.currency
                    ? el.validations.currency
                    : "USD",
              },
            };
          }
          if (el.type == "DATE") {
            obj = { ...obj, ...{ date: el.label } };
          }
          if (el.type == "FORMULA") {
            obj = {
              ...obj,
              ...{
                formula: el.formula,
                result_type: el.result_type ? el.result_type : "NUMBER",
              },
            };
          }
          elements.push(obj);
        } else if (this.field.key != el.key && el.input_type == "DATA_TABLE") {
          if (this.el.data_table_columns && this.el.data_table_columns.length) {
            let nestedElements = this.getFormulaAllowedFieldsForDataTable(
              this.el.data_table_columns,
              this.el.key
            );
            elements = [...elements, ...nestedElements];
          }
        }
      });
      console.log("elements 123", elements, this.field);
      return elements;
    },

    getSelfTemplateFields() {
      return (selfTemplate, key) => {
        let elements = [];
        for (let index = 0; index < selfTemplate.length; index++) {
          if (selfTemplate[index].input_type === "NUMBER") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
            });
          }

          if (selfTemplate[index].input_type === "CURRENCY") {
            console.log("currency", selfTemplate[index].validations.currency);

            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              currency_type: selfTemplate[index].validations.currency,
            });
          }

          if (selfTemplate[index].input_type === "DATE") {
            console.log("result of date", selfTemplate[index]);
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
            });
          }

          if (selfTemplate[index].input_type === "TIME") {
            console.log("result of date", selfTemplate[index]);
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
            });
          }

          if (selfTemplate[index].input_type === "LIST") {
            console.log("result of date", selfTemplate[index]);
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
            });
          }
          if (selfTemplate[index].input_type === "DATE_TIME_RANGE") {
            console.log("result of date", selfTemplate[index]);
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
            });
          }

          if (selfTemplate[index].input_type === "ENTITY_VARIABLE") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].inputType,
            });
          }

          if (
            selfTemplate[index].input_type === "SELECT" &&
            selfTemplate[index].is_list
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
            });
          }

          if (selfTemplate[index].input_type === "FORMULA") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              formula: selfTemplate[index].formula,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
            });
          }

          if (
            selfTemplate[index].input_type === "AGGREGATE_FUNCTION" &&
            key != selfTemplate[index].key
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
            });
          }
        }
        return elements;
      };
    },

    getFields() {
      let elements = [];
      for (let i = 0; i < this.templatesData.length; i++) {
        let fields =
          this.templatesData[i]._id != this.selfTemplateId
            ? this.templatesData[i].sections[0].fields
            : [];
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].input_type === "NUMBER") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].input_type,
            });
          }

          if (fields[j].input_type === "CURRENCY") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].input_type,
              currency_type: fields[j].validations.currency,
            });
          }
          if (fields[j].input_type === "FORMULA") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              formula: fields[j].formula,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].input_type,
              result_type: fields[j].result_type,
            });
          }

          // if (fields[j].input_type === "ENTITY_VARIABLE") {
          //   elements.push({
          //     template_id: this.templatesData[i]._id,
          //     template_name: this.templatesData[i].name,
          //     field_id: fields[j]._id,
          //     field_name: fields[j].label,
          //     key: fields[j].key,
          //     formula: fields[j].formula,
          //     u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
          //     input_type: fields[j].inputType,
          //   });
          // }
        }
      }

      let selfTemplateFields = this.getSelfTemplateFields(
        this.selfTemplate,
        this.field.key
      );

      if (this.field.data_table_field_index > -1) {
        let dataTableFields = this.selfTemplate.filter(
          (e) => e.input_type == "DATA_TABLE"
        );

        let dataTableFieldsElements = dataTableFields.map(
          (e) => e.data_table_columns
        );
        dataTableFieldsElements = [].concat.apply([], dataTableFieldsElements);

        let selfTemplateDataTableFields = this.getSelfTemplateFields(
          dataTableFieldsElements,
          this.field.key
        );

        elements = [...selfTemplateDataTableFields, ...elements];
      }

      elements = [...selfTemplateFields, ...elements];

      return elements;
    },
  },
  mounted() {
    this.fetchGlobalVaribales();
    this.getActivePaymentSystemsData();
    this.fetchEntities();
    this.updateRules = this.field.validations.updateRules;
    if (!this.field.validations.defaultRedirectUrl) {
      this.field.validations.defaultRedirectUrl = Config.APP_URL + "entity-payment-status-update?SessionData="
    }
  },
  data() {
    return {
      selectedEntityFields: [],
      paymentModes: [
        "ACH", "CREDIT CARD"
      ],
      CurrencyTypes: [
        {
          value: "USD",
          name: "$",
        },
        {
          value: "CAD",
          name: "$",
        },
        {
          value: "AUD",
          name: "$",
        },
        {
          value: "INR",
          name: "₹",
        },
        // {
        //   value: "EUR",
        //   name: "€",
        // },
      ],
      successRule: {},
      failureRule: {},
      updateRules: {
        success: [],
        failure: []
      },
      actionTypesList: ["CREATE", "UPDATE", "DELETE"]
    };
  },
  methods: {
    updateRedirectUrl() {
      if (this.field.validations.paymentSystems && this.field.validations.paymentSystems[0]) {
        this.field.validations.defaultRedirectUrl = Config.APP_URL + "entity-payment-status-update?SessionData=" + this.field.validations.paymentSystems[0]
      }
    },
    async fetchEntities() {
      try {
        await this.$store.dispatch("entities/fetchEntities", {
          get_all: true,
        });

      } catch (error) {
        console.log(error);
      }
    },
    async setEntityFields(type) {
      try {

        let entityId = '';
        if (type === "SUCCESS") {
          console.log(this.successRule)
          this.successRule.entity = this.successRule.entity_info._id
          entityId = this.successRule.entity
        }
        else if (type === "FAILURE") {
          this.failureRule.entity = this.failureRule.entity_info._id
          entityId = this.failureRule.entity
        }
        this.loading = true;
        await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: entityId,
        });

        this.selectedEntityFields = [];
        if (this.getEntityById && this.getEntityById.templates) {
          this.getEntityById.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(e.templateInfo),
              ];
            }
          });
          console.log("this.selectedEntityFields", this.selectedEntityFields);
          // await this.checkRationshipsData(entityId);
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    addNewCondition(actionType, addAction) {
      console.log(this.updateRules)
      // this.currentRule.conditions.push({ ...this.formRule });
      if (actionType === 'SUCCESS' && addAction) {
        // this.updateRules.success.push({ ...this.successRule })
         this.field.validations.updateRules.success.push({ ...this.successRule })
        this.successRule = {}
        this.failureRule = {}
        //   console.log(actionType)
        // // this.addNewAction();
        // // this.addOrEditingRules = true;
      }
      else if (actionType === 'FAILURE' && addAction) {
        // console.log(actionType)
        // this.updateRules.failure.push({ ...this.failureRule })
        this.field.validations.updateRules.failure.push({ ...this.failureRule })
        this.failureRule = {}
        this.successRule = {}
      }
    },
    deleteCondition(actionType, index) {
      console.log(index, actionType);
      if (actionType === "SUCCESS" && this.updateRules.success && this.updateRules.success.length) {
        this.updateRules.success.splice(index, 1);
        this.field.validations.updateRules.success.splice(index, 1);
      }
      else if (actionType === "FAILURE" && this.updateRules.failure && this.updateRules.failure.length) {
        this.updateRules.failure.splice(index, 1);
        this.field.validations.updateRules.failure.splice(index, 1);
      }
    },
    getCurrencyType() {
      if (this.field.validations.entityVariable) {
        this.field.validations.currency = this.field.validations && this.field.validations.entityVariable &&      this.field.validations.entityVariable.currency_type ? this.field.validations.entityVariable.currency_type : "USD";
      }
    },
    getLabelName(item) {
      if (item.template_name) {
        return item.template_name + "/" + item.field_name;
      } else {
        return item.field_name;
      }
    },
    checkDisabled(type) {
      // , item = ""
      if (
        // type == "field" ||
        type == "number" ||
        type == "currency"
        // ||
        // type == "global_variable" ||
        // type == "date" ||
        // type == "time" ||
        // type == "list"
      ) {
        if (this.field.selected_fields.length) {
          if (this.activeClassIndex > -1) {
            let fieldData = this.field.selected_fields[this.activeClassIndex];
            if (
              fieldData &&
              (
                // fieldData.type == "FIELD" ||
                fieldData.type == "NUMBER" ||
                // fieldData.type == "DATE" ||
                // fieldData.type == "TIME" ||
                // fieldData.type == "LIST" ||
                fieldData.type == "CURRENCY"
                // ||
                // fieldData.type == "GLOBAL_VARIABLE" ||
                // fieldData.type == "FORMULA"
              )
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "FIELD" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "NUMBER" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "DATE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "TIME" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "DATE_TIME_RANGE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "LIST" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "GLOBAL_VARIABLE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "FORMULA" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "CURRENCY" ||
              (this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "PARENTHESES" &&
                this.field.selected_fields[
                  this.field.selected_fields.length - 1
                ].parentheses == ")")
            );
          }
        }
        return false;
      }
      else {
        return false;
      }
    },
    async getActivePaymentSystemsData() {
      // this.loading = true;
      await this.$store.dispatch("paymentGateway/fetchInitSystemsData");
      // this.loading = false;
    },
    async fetchGlobalVaribales() {

      let params = {
        get_all: true,
        input_type: this.field.input_type
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        x => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    }
  }
};
</script>

<style lang="scss"></style>